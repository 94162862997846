import React from "react";

import CNSDigikey from "./images/CNSDigikey.svg";
import CNSAmbulatorioVirtual from "./images/CNSAmbulatorioVirtual.svg";
import CNSPEP from "./images/CNSPEP.svg";
import CNSTelemed from "./images/CNSTelemed.svg";

import { Button } from "@fluentui/react-components";
import { ArrowRight20Regular } from "@fluentui/react-icons";
import ScreenHelper from "../helpers/ScreenHelper";

const styleSmall = {
  FeaturesBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center"
  },

  ActionButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "18px 23px",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
    color: "#008080",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "normal"
  },

  Feature: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "12px",
    justifyContent: "center",
    alignItems: "center"
  },

  Text: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "0px 9px",
    justifyContent: "center",
    alignItems: "center",

    Title: {
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "52px",
      textAlign: "center",
      color: "#004040"
    },

    Description: {
      fontSize: "16px",
      lineHeight: "32px",
      textAlign: "center",
      color: "#8EA9C1"
    }
  },

  CardList: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    justifyContent: "center",
    alignItems: "stretch",

    Card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
      gap: "16px",
      padding: "32px 16px",
      backgroundColor: "#ffffff",
      boxShadow: "0px 4px 8px rgba(0,0,0,0.25)",
      borderRadius: "12px",
      flex: 1,

      CardImage: {
        height: "64px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },

      CardTitle: {
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "32px",
        color: "#004040",
        textAlign: "center"
      },

      CardText: {
        fontSize: "15px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#8EA9C1"
      }
    }
  }
};

const styleLarge = {
  FeaturesBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center"
  },

  ActionButton: {
    color: "#008080",
    border: "none"
  },

  Feature: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "64px 128px"
  },

  Text: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "0px 9px",
    justifyContent: "center",
    alignItems: "center",

    Title: {
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "52px",
      textAlign: "center",
      color: "#004040"
    },

    Description: {
      fontSize: "16px",
      lineHeight: "32px",
      textAlign: "center",
      color: "#8EA9C1"
    }
  },

  CardList: {
    display: "flex",
    gap: "32px",
    justifyContent: "center",
    alignItems: "stretch",
    height: "360px",

    Card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
      gap: "16px",
      padding: "32px 16px",
      backgroundColor: "#ffffff",
      boxShadow: "0px 4px 8px rgba(0,0,0,0.25)",
      borderRadius: "12px",
      flex: 1,

      CardImage: {
        height: "64px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },

      CardTitle: {
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "32px",
        color: "#004040",
        textAlign: "center"
      },

      CardText: {
        fontSize: "15px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#8EA9C1"
      }
    }
  }
};

const styles = ScreenHelper().isSmall() 
  ? { ...styleSmall }
  : { ...styleLarge };

const FeaturesBlock = ({ title, description }) => {
  const cards = [
    {
      title: "Assinatura Digital",
      text: "Módulo de assinatura e verificação digital de documentos, prontuários, receitas e muito mais. Você pode trazer seu certificado ou assinar um novo com a CNS Digikey.",
      image: <img src={CNSDigikey} alt="CNS Digikey logo" />
    },
    {
      title: "Telemedicina",
      text: "Atenda seus pacientes remotamente com o módulo de telemedicina da CNS Brasil. Encontre novos pacientes também com a clínica virtual CNS.",
      image: <img src={CNSTelemed} alt="CNS Telemed logo" />
    },
    {
      title: "Ambulatório Virtual",
      text: "Com o Ambulatório Virtual você pode controlar seu estoque de medicamentos, efetuar pedidos com fornecedores credenciados e obter informações sobre fórmulas e compostos.",
      image: <img src={CNSAmbulatorioVirtual} alt="CNS Ambulatório Virtual logo" />
    },
    {
      title: "Arquivo do Paciente",
      text: "Com o CNS PEP você tem acesso ao histórico do paciente de remédios, hábitos, cirurgias e muito mais, respeitando as leis da LGPD.",
      image: <img src={CNSPEP} alt="CNS PEP logo" />
    }
  ];

  return (
    <div style={styles.FeaturesBlock}>
      <div style={styles.Feature}>
        <div style={styles.Text}>
          <span style={styles.Text.Title}>{title}</span>
          <span style={styles.Text.Description}>{description}</span>
        </div>
        <div style={styles.CardList}>
          {cards.map(card => <div key={card.title} style={styles.CardList.Card}>
            <div style={styles.CardList.Card.CardImage}>
              {card.image}
            </div>
            <div style={styles.CardList.Card.CardTitle}>
              {card.title}
            </div>
            <div style={styles.CardList.Card.CardText}>
              {card.text}
            </div>
          </div>)}
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", padding: "32px 0px" }}>
          <Button style={styles.ActionButton}>
            Conhecer todos os sistemas <div style={{ width: "8px" }}></div>
            <ArrowRight20Regular />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeaturesBlock;
