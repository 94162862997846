
const ScreenHelper = () => {
  const { innerWidth, innerHeight } = window;

  const isSmall = () => window.matchMedia("(max-width: 640px)").matches;
  const isMedium = () => window.matchMedia("(min-width: 768px)").matches;
  const isLarge = () => window.matchMedia("(min-width: 1024px)").matches;
  const isExtraLarge = () => window.matchMedia("(min-width: 1280px)").matches;

  return {
    width: innerWidth,
    height: innerHeight,
    isSmall: isSmall,
    isMedium: isMedium,
    isLarge: isLarge,
    isExtraLarge: isExtraLarge
  };
}

export default ScreenHelper;
