import React from "react";

import CNSBrasilLogoInverted from "./images/CNSBrasilLogo_inverted.svg";
import GoogleIcon from "./images/GoogleIcon.svg";
import TwitterIcon from "./images/TwitterIcon.svg";
import InstagramIcon from "./images/InstagramIcon.svg";
import LinkedinIcon from "./images/LinkedinIcon.svg";
import ScreenHelper from "../helpers/ScreenHelper";

const styleSmall = {
  FooterBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "12px",
    backgroundColor: "#004040"
  },

  Feature: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    justifyContent: "start",
    alignItems: "stretch",
  },

  Content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px"
  },

  BrandColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "32px",
    flex: 1
  },

  BrandLogo: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "0px 12px",
    alignItems: "center",

    Logo: {
      width: "48px",
      height: "48px"
    },

    BrandName: {
      fontSize: "24px",
      lineHeight: "18px",
      color: "#ffffff"
    }
  },

  Slogan: {
    fontSize: "16px",
    lineHeight: "28px",
    color: "#D3D6D8"
  },

  SocialLinks: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",

    Link: {
      display: "flex",
      width: "56px",
      height: "56px",
      borderRadius: "28px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba( 255, 255, 255, 0.25)"
    }
  },

  Column: {
    display: "none",
  },

  Copyright: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "center",
    alignItems:  "center",
    padding: "16px 0px",

    Text: {
      fontSize: "14px",
      color: "#008080",
      textAlign: "center"
    }
  }
};

const styleLarge = {
  FooterBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "64px 128px",
    backgroundColor: "#004040"
  },

  Feature: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    justifyContent: "start",
    alignItems: "stretch",
  },

  Content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "start",
    gap: "16px"
  },

  BrandColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "24px",
    flex: 1
  },

  BrandLogo: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "0px 12px",
    alignItems: "center",

    Logo: {
      width: "48px",
      height: "48px"
    },

    BrandName: {
      fontSize: "24px",
      lineHeight: "18px",
      color: "#ffffff"
    }
  },

  Slogan: {
    fontSize: "16px",
    lineHeight: "28px",
    color: "#D3D6D8"
  },

  SocialLinks: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",

    Link: {
      display: "flex",
      width: "56px",
      height: "56px",
      borderRadius: "28px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba( 255, 255, 255, 0.25)"
    }
  },

  Column: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    flex: 1,

    Title: {
      fontWeight: "bold",
      fontSize: "18px",
      color: "#ffffff"
    },

    FeatureLink: {
      fontSize: "16px",
      color: "#D3D6D8"
    }
  },

  Copyright: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "center",
    alignItems:  "center",
    padding: "16px 0px",

    Text: {
      fontSize: "14px",
      color: "#008080",
      textAlign: "center"
    }
  }
};

const styles = ScreenHelper().isSmall() 
  ? { ...styleSmall }
  : { ...styleLarge };

const FooterBlock = () => {
  return (
    <div style={styles.FooterBlock}>
      <div style={styles.Feature}>
        <div style={styles.Content}>
          <div style={styles.BrandColumn}>
            <div style={styles.BrandLogo}>
              <img src={CNSBrasilLogoInverted} style={styles.BrandLogo.Logo} alt="CNS Brasil logo" />
              <span style={styles.BrandLogo.BrandName}>CNS Brasil</span>
            </div>
            <span style={styles.Slogan}>Conectando o mundo da saúde.</span>
            <div style={styles.SocialLinks}>
              <div style={styles.SocialLinks.Link}>
                <img src={GoogleIcon} width={18} height={18} alt="Google icone" />
              </div>
              <div style={styles.SocialLinks.Link}>
                <img src={TwitterIcon} width={18} height={18} alt="Twitter icone" />
              </div>
              <div style={styles.SocialLinks.Link}>
                <img src={InstagramIcon} width={18} height={18} alt="Instagram icone" />
              </div>
              <div style={styles.SocialLinks.Link}>
                <img src={LinkedinIcon} width={18} height={18} alt="Linkedin icone" />
              </div>
            </div>
          </div>
          <div className="Spacer"></div>

          <div style={styles.Column}>
            <span style={styles.Column.Title}>Produtos</span>
            <span style={styles.Column.FeatureLink}>CNS Clinica</span>
            <span style={styles.Column.FeatureLink}>CNS Agenda</span>
            <span style={styles.Column.FeatureLink}>CNS Digikey</span>
            <span style={styles.Column.FeatureLink}>CNS Paciente</span>
            <span style={styles.Column.FeatureLink}>Todos os produtos</span>
          </div>

          <div style={styles.Column}>
            <span style={styles.Column.Title}>Serviços</span>
            <span style={styles.Column.FeatureLink}>Fornecedores</span>
            <span style={styles.Column.FeatureLink}>Mapa da Saúde</span>
            <span style={styles.Column.FeatureLink}>APIs</span>
          </div>

          <div style={styles.Column}>
            <span style={styles.Column.Title}>Empresas</span>
            <span style={styles.Column.FeatureLink}>Sobre a CNS</span>
            <span style={styles.Column.FeatureLink}>Política de Privacidade</span>
            <span style={styles.Column.FeatureLink}>Trabalhe na CNS</span>
          </div>

          <div style={styles.Column}>
            <span style={styles.Column.Title}>Mais</span>
            <span style={styles.Column.FeatureLink}>Blog CNS</span>
          </div>
        </div>
        <div style={styles.Copyright}>
          <span style={styles.Copyright.Text}>Copyright © 2024.  Centro Nacional de Saúde LTDA - CNPJ: 53.498.628/0001-25</span>
        </div>
      </div>
    </div>
  );
};

export default FooterBlock;
