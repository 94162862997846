import { createLightTheme, createDarkTheme } from "@fluentui/react-components";

const cnsBrasilTheme = {
  10: "#020404",
  20: "#0F1C1B",
  30: "#132E2E",
  40: "#153C3C",
  50: "#154A4A",
  60: "#135858",
  70: "#106767",
  80: "#087676",
  90: "#1B8584",
  100: "#3F9291",
  110: "#5A9F9E",
  120: "#72ACAB",
  130: "#89B9B8",
  140: "#A0C7C6",
  150: "#B7D4D3",
  160: "#CEE2E1"
};

const lightTheme = {
  ...createLightTheme(cnsBrasilTheme),
};

const darkTheme = {
  ...createDarkTheme(cnsBrasilTheme),
};


darkTheme.colorBrandForeground1 = cnsBrasilTheme[110];
darkTheme.colorBrandForeground2 = cnsBrasilTheme[120];

export const CNSBrasilTheme = {
  colors: cnsBrasilTheme,
  light: lightTheme,
  dark: darkTheme
};
