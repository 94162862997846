import { Button } from "@fluentui/react-components";
import React from "react";

import Dashboard from "./images/Dashboard.svg";
import ScreenHelper from "../helpers/ScreenHelper";

const styleSmall = {
  Slider: {
    display: "flex",
    flexDirection: "row",
    gap: "10px"
  },

  SliderInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    padding: "12px"
  },

  SliderInfoTitle: {
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "76px",
    color: "#ffffff",
    textAlign: "center"
  },

  SliderInfoDescription: {
    fontSize: "16px",
    lineHeight: "26px",
    color: "#ffffff",
    textAlign: "center"
  },

  SliderInfoActions: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "10px",
    justifyContent: "stretch",

    TouchButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "18px 23px",
      borderRadius: "12px",
      backgroundColor: "#ffffff",
      color: "#008080",
      textAlign: "center",
      fontSize: "16px",
      flexGrow: 1,
      fontWeight: "normal"
    }
  },

  SliderIllustration: {
    display: "none"
  }
};

const styleLarge = {
  Slider: {
    display: "flex",
    flexDirection: "row",
    gap: "10px"
  },

  SliderInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    padding: "128px",
    paddingRight: "0px",
    maxWidth: "660px",
    minWidth: "450px"
  },

  SliderInfoTitle: {
    fontWeight: "bold",
    fontSize: "64px",
    lineHeight: "76px",
    color: "#ffffff"
  },

  SliderInfoDescription: {
    fontSize: "16px",
    lineHeight: "26px",
    color: "#ffffff"
  },

  SliderInfoActions: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "10px"
  },

  SliderIllustration: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "end"
  }
};

const styles = ScreenHelper().isSmall()
  ? { ...styleSmall }
  : { ...styleLarge };

const SlideInfo = () => {
  return (
    <div style={styles.SliderInfo}>
      <span style={styles.SliderInfoTitle}>Sua plataforma de sa&uacute;de completa</span>
      <span style={styles.SliderInfoDescription}>CNS Brasil é uma plataforma de saúde completamente integrada, com foco em agilizar os processos e aproximar pacientes de clínicas e fornecedores.</span>
      <div style={styles.SliderInfoActions}>
        <Button appearance="secondary" style={{ color: "#008080", border: "none", ...styles.SliderInfoActions.TouchButton }}>Ver funcionalidades</Button>
        <Button appearance="transparent" style={{ color: "#ffffff", display: ScreenHelper().isSmall() ? "none" : "flex" }}>Solicitar demonstração</Button>
      </div>
    </div>
  )
};

const SlideIllustration = () => {
  return (
    <div style={styles.SliderIllustration}>
      <div style={{ height: "560px" }}>
        <img src={Dashboard} alt="CNS Brasil ilustração de dashboard" />
      </div>
    </div>
  );
};

const Slider = () => {
  return (
    <div style={styles.Slider}>
      <SlideInfo></SlideInfo>
      <SlideIllustration></SlideIllustration>
    </div>
  );
};

export default Slider;
