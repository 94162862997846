import React from "react";

import { Button } from "@fluentui/react-components";
import { ArrowRight20Regular } from "@fluentui/react-icons";

import ScreenHelper from "../helpers/ScreenHelper";

const styleSmall = {
  ContentBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "start",
    alignItems: "center",
  },

  Feature: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    justifyContent: "center",
    alignItems: "center"
  },

  FeatureImage: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  FeatureText: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    justifyContent: "stretch",
    alignItems: "center",

    Title: {
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "52px",
      color: "#004040",
      textAlign: "center"
    },
    Description: {
      fontSize: "16px",
      lineHeight: "32px",
      color: "#90A3B4",
      textAlign: "center"
    },
    ActionButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "18px 23px",
      borderRadius: "12px",
      backgroundColor: "#ffffff",
      color: "#008080",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "normal"
    }
  }
};

const styleLarge = {
  ContentBlock: {
    display: "flex",
    gap: "10px",
    justifyContent: "start",
    alignItems: "center",
  },

  Feature: {
    display: "flex",
    flexDirection: "row",
    padding: "64px 128px",
    justifyContent: "space-between",
    alignItems: "center"
  },

  FeatureImage: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  FeatureText: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    justifyContent: "start",
    alignItems: "start",

    Title: {
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "52px",
      color: "#004040"
    },
    Description: {
      fontSize: "16px",
      lineHeight: "32px",
      color: "#90A3B4"
    },
    ActionButton: {
      color: "#008080",
      border: "none"
    }
  }
};

const styles = ScreenHelper().isSmall() 
  ? { ...styleSmall }
  : { ...styleLarge };

const ContentBlock = ({ id, title, description, image, inverted = false }) => {
  if(ScreenHelper().isSmall()) {
    inverted = false;
  }

  const ImageSection = () => <div style={styles.FeatureImage}>
    {image}
  </div>;

  const TextSection = () => <div style={styles.FeatureText}>
    <span style={styles.FeatureText.Title}>{title}</span>
    <span style={styles.FeatureText.Description}>{description}</span>
    <Button style={styles.FeatureText.ActionButton}>Saiba mais <div style={{ width: "8px" }}></div><ArrowRight20Regular /></Button>
  </div>;

  return (
    <div style={styles.ContentBlock} id={id}>
      <div style={styles.Feature}>
        {inverted 
          ? (<>
            <TextSection />
            <ImageSection />
          </>)
          : (<>
            <ImageSection />
            <TextSection />
          </>)
        }
      </div>
    </div>
  );
};

export default ContentBlock;