import React from "react";
import MasterHead from './components/MasterHead';

import CNSClinicaImage from "./components/images/CNSClinicaImage.svg";
import CNSPacienteImage from "./components/images/CNSPacienteImage.svg";
import CNSAgendaImage from "./components/images/CNSAgendaImage.svg";

import ContentBlock from "./components/ContentBlock";
import FeaturesBlock from "./components/FeaturesBlock";
import FooterBlock from "./components/FooterBlock";
import PlansBlock from "./components/PlansBlock";

import ScreenHelper from "./helpers/ScreenHelper";

const styleSmall = {
  Home: {
    display: "flex",
    flexDirection: "column",
    gap: "42px",
    backgroundColor: "#FFFFFF"
  }
};

const styleLarge = {
  Home: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    backgroundColor: "#FFFFFF"
  }
};

const styles = ScreenHelper().isSmall() 
  ? { ...styleSmall }
  : { ...styleLarge };

function App() {
  return (
    <div style={styles.Home}>
      <MasterHead></MasterHead>
      <div style={{ height: "128px", display: ScreenHelper().isSmall() ? "none" : "flex" }}></div>
      <ContentBlock 
        id="cns-clinica"
        title="CNS Clínica: Gestão de clínica integrada e inteligente"
        description="Tenha controle e visão completa da sua clínica com o CNS Clínica. Sistema de gestão e administração de clínicas, dando capacidade ao gestor de customizar conforme a sua própria estratégia, com auxilio de IA para prever os resultados e ajudar na tomada de decisões."
        image={<img src={CNSClinicaImage} width={ScreenHelper().isSmall() ? "350px" : "auto" } alt="Ilustração da aplicação CNS Clinica" />}
      ></ContentBlock>

      <ContentBlock 
        inverted={true}
        id="cns-agenda"
        title="CNS Agenda: Sua agenda inteligente com IA"
        description="A CNS Agenda possui inteligência própria, identificando os padrões de agendamento da clínica e sugerindo os melhores horários de acordo com o procedimento e médico selecionado. Também possui integração com agendamento online, via Whatsapp™ e o aplicativo CNS Paciente, liberando sua equipe para tarefas mais relevantes."
        image={<img src={CNSAgendaImage} width={ScreenHelper().isSmall() ? "350px" : "auto" } alt="Ilustração da aplicação CNS Agenda" />}
      ></ContentBlock>

      <ContentBlock 
        id="cns-paciente"
        title="CNS Paciente: Você no controle da sua saúde"
        description="Com o aplicativo CNS Paciente, você terá mais controle sobre sua saúde, seu histórico de atendimentos, exames, receitas e até um canal de mensagem com seu médico."
        image={<img src={CNSPacienteImage} width={ScreenHelper().isSmall() ? "350px" : "auto" } alt="Ilustração do aplicativo para celular CNS Paciente" />}
      ></ContentBlock>

      <FeaturesBlock title="Uma assinatura, múltiplos sistemas" description="Assinando a CNS Brasil você ganha acesso a todos sistemas da plataforma, sem custos adicionais e sem surpresas." />

      <PlansBlock id="planos" />

      <FooterBlock />
    </div>
  );
}

export default App;
