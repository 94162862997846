import React from "react";
import { Button, Tab, TabList } from "@fluentui/react-components";
import CNSBrasilLogo from "./images/CNSBrasilLogo.svg";
import Slider from "./Slider";
import ScreenHelper from "../helpers/ScreenHelper";

const styleSmall = {
  MasterHead: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    paddingTop: "32px",
    background: "linear-gradient( #008080, #39A7A7)"
  },

  Header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px"
  },

  BrandLogo: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "12px 0px",
    alignItems: "center"
  },

  BrandText: {
    color: "#FFFFFF",
    fontSize: "32px",
    lineHeight: "32px"
  },

  MainNav: {
    display: "none",
  }
};

const styleLarge = {
  MasterHead: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    paddingTop: "32px",
    background: "linear-gradient( #008080, #39A7A7)"
  },

  Header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 128px"
  },

  BrandLogo: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "12px 0px",
    alignItems: "center"
  },

  BrandText: {
    color: "#FFFFFF",
    fontSize: "32px",
    lineHeight: "32px"
  },

  MainNav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
    gap: "24px"
  },

  LoginButton: {
    color: "#008080",
    border: "none"
  }
};

const styles = ScreenHelper().isSmall() 
  ? { ...styleSmall }
  : { ...styleLarge };

const MasterHead = () => {
  return (
    <div style={styles.MasterHead} id="inicio">
      <header style={styles.Header}>
        <div style={styles.BrandLogo}>
          <img src={CNSBrasilLogo} width={48} height={48} alt="CNS Brasil logo" />
          <span style={styles.BrandText}>CNS Brasil</span>
        </div>

        <div style={styles.MainNav}>
          <TabList>
            <Tab value="tabInicio" className="MainNavTabItem"onClick={() => document.querySelector("#inicio")?.scrollIntoView({ behavior: "smooth", block: "start" })}>In&iacute;cio</Tab>
            <Tab value="tabCNSClinica" className="MainNavTabItem"onClick={() => document.querySelector("#cns-clinica")?.scrollIntoView({ behavior: "smooth", block: "start" })}>CNS Clinica</Tab>
            <Tab value="tabCNSAgenda" className="MainNavTabItem"onClick={() => document.querySelector("#cns-agenda")?.scrollIntoView({ behavior: "smooth", block: "start" })}>CNS Agenda</Tab>
            <Tab value="tabCNSPaciente" className="MainNavTabItem" onClick={() => document.querySelector("#cns-paciente")?.scrollIntoView({ behavior: "smooth", block: "start" })}>CNS Paciente</Tab>
            <Tab value="tabPlanos" className="MainNavTabItem" onClick={() => document.querySelector("#planos")?.scrollIntoView({ behavior: "smooth", block: "start" })}>Planos</Tab>
          </TabList>
          <Button appearance="secondary" style={styles.LoginButton}>Acessar</Button>
        </div>
      </header>

      <Slider></Slider>
    </div>
  );
};

export default MasterHead;
